import React from 'react';
import * as classes from './Header.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import {Link} from 'gatsby';
import hero from '../../images/hero-main.jpg';

export default function Header() {

  return (
    <header className={classes.header}>
      <img src={hero} alt="grupa ludzi robiących sobie zdjęcie" className={classes.header__bg}/>
      {/*<StaticImage className={classes.header__bg} src="../../images/hero-main.jpg" placeholder="blurred" quality={95}*/}
      {/*             alt="grupa ludzi robiących sobie zdjęcie"/>*/}
      <div className={classes.header__wrapper}>
        <div className={classes.header__left}>
          <div className={classes.header__logos}>
            <StaticImage className={classes.header__logo} src="../../images/logo-cursor.png" placeholder="blurred"
                         quality={90} alt="Logo CURSOR" objectFit='contain'/>
            <StaticImage className={classes.header__logo} src="../../images/logo-bat.png" placeholder="blurred"
                         quality={90} alt="Logo British American Tobacco" objectFit='contain'/>
          </div>
          <div className={classes.header__wrapper_content}>
            <h1 className={classes.header__title}>
              OEX Cursor dla BAT łączy ludzi&nbsp;z
              <StaticImage className={classes.header__title_img} src="../../images/passion.png" placeholder="blurred"
                           quality={90} alt="passion"/>
            </h1>
            <p className={classes.header__subtitle}>
              Całe Twoje życie składa się z pasji, więc masz też pasję do nowej pracy?
            </p>
            <p className={classes.header__text}>
              Wspaniale, w naszym teamie otaczamy się właśnie takimi osobami!
            </p>
            <Link to="/#applicationSteps" className={classes.header__button}>Sprawdź i aplikuj!</Link>
          </div>
        </div>
        <div className={classes.header__triangle}></div>
      </div>
    </header>
  )
}
