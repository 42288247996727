import React, {useRef, useEffect} from 'react';
import * as classes from './VideoModal.module.scss';


const VideoModal = ({closeVideo, url}) => {

  return (
    <div className={classes.videoContainer}>
      <video className={classes.customVideo} controls autoPlay>
        <source src={url} type="video/mp4" title="Video"/>
      </video>
      <div className={classes.videoClose} onClick={closeVideo}>×</div>
    </div>
  )
}

export default VideoModal
