import React from 'react';
import * as classes from './Offer.module.scss';
import { StaticImage } from "gatsby-plugin-image";

export default function Offer() {
    return (
        <section className={classes.offer} id='co-oferujemy'>
            <div className={classes.offer__container}>
                <div className={classes.offer__wrapper}>
                    <h3 className={classes.offer__nameSection}>Co oferujemy?</h3>
                    <h2 className={classes.offer__title}>
                        Zapoznaj się z warunkami naszej oferty!
                    </h2>
                    <div className={classes.offer__lists}>
                        <ul className={`${classes.offer__list} ${classes.list}`}>
                            <li>
                               <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                                <p>stawka godzinowa od 45-70 zł brutto</p>
                            </li>
                            <li>
                               <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                                <p>atrakcyjne bonusy - do 70% wynagrodzenia podstawowego</p>
                            </li>
                          <li>
                            <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                            <p>elastyczne godziny pracy</p>
                          </li>
                          <li>
                            <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                            <p>profesjonalne szkolenia</p>
                          </li>
                          <li>
                            <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                            <p>stała opieka koordynatora projektu</p>
                          </li>
                        </ul>
                        <ul className={`${classes.offer__list} ${classes.list}`}>
                          <li>
                            <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                            <p>możliwość awansu</p>
                          </li>
                          <li>
                            <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                            <p>karta Multisport i prywatna opieka medyczna</p>
                          </li>
                            <li>
                               <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                                <p>bardzo miła atmosfera pracy</p>
                            </li>
                            <li>
                               <StaticImage className={classes.list__img} src="../../images/arrow-right.svg" placeholder="blurred" quality={95} alt="arrow" />
                                <p>umowa zlecenie</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
