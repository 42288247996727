// extracted by mini-css-extract-plugin
export var appNameSection = "VideoSecond-module--app-name-section--Ndbvv";
export var appText = "VideoSecond-module--app-text--AAcvF";
export var appButton = "VideoSecond-module--app-button--pgO04";
export var appButtonElement = "VideoSecond-module--app-button-element--IC8Vf";
export var appContainer = "VideoSecond-module--app-container--ie3bU";
export var container = "VideoSecond-module--container--fecws";
export var appRow = "VideoSecond-module--app-row--k-2AA";
export var appCol = "VideoSecond-module--app-col--CcZMR";
export var appCol1 = "VideoSecond-module--app-col-1--Cx1CZ";
export var appCol2 = "VideoSecond-module--app-col-2--nYLOH";
export var appCol3 = "VideoSecond-module--app-col-3--paKa+";
export var appCol4 = "VideoSecond-module--app-col-4--dQ+Kv";
export var appCol5 = "VideoSecond-module--app-col-5--UITb0";
export var appCol6 = "VideoSecond-module--app-col-6--sZXS+";
export var appCol7 = "VideoSecond-module--app-col-7--8mfoO";
export var appCol8 = "VideoSecond-module--app-col-8--0pWiE";
export var appCol9 = "VideoSecond-module--app-col-9--5mK8l";
export var appCol10 = "VideoSecond-module--app-col-10--X6HhI";
export var appCol11 = "VideoSecond-module--app-col-11--NPrM0";
export var appCol12 = "VideoSecond-module--app-col-12--HVrUq";
export var appColXl1 = "VideoSecond-module--app-col-xl-1--ODTXX";
export var appColXl2 = "VideoSecond-module--app-col-xl-2--h1vfM";
export var appColXl3 = "VideoSecond-module--app-col-xl-3--iqAEw";
export var appColXl4 = "VideoSecond-module--app-col-xl-4--vJ48p";
export var appColXl5 = "VideoSecond-module--app-col-xl-5--NwouE";
export var appColXl6 = "VideoSecond-module--app-col-xl-6--0Kr0f";
export var appColXl7 = "VideoSecond-module--app-col-xl-7--2dqWw";
export var appColXl8 = "VideoSecond-module--app-col-xl-8--AI3Bv";
export var appColXl9 = "VideoSecond-module--app-col-xl-9--EGsna";
export var appColXl10 = "VideoSecond-module--app-col-xl-10--7D3lA";
export var appColXl11 = "VideoSecond-module--app-col-xl-11--lGzVO";
export var appColXl12 = "VideoSecond-module--app-col-xl-12--bURSw";
export var appColLg1 = "VideoSecond-module--app-col-lg-1--e7oFR";
export var appColLg2 = "VideoSecond-module--app-col-lg-2--I1DoK";
export var appColLg3 = "VideoSecond-module--app-col-lg-3--8ttSH";
export var appColLg4 = "VideoSecond-module--app-col-lg-4--EfZno";
export var appColLg5 = "VideoSecond-module--app-col-lg-5--a1jSc";
export var appColLg6 = "VideoSecond-module--app-col-lg-6--LhHdU";
export var appColLg7 = "VideoSecond-module--app-col-lg-7--a1z7f";
export var appColLg8 = "VideoSecond-module--app-col-lg-8--BukdP";
export var appColLg9 = "VideoSecond-module--app-col-lg-9--eGgiC";
export var appColLg10 = "VideoSecond-module--app-col-lg-10--QhVNI";
export var appColLg11 = "VideoSecond-module--app-col-lg-11--VKXul";
export var appColLg12 = "VideoSecond-module--app-col-lg-12--FpgI7";
export var appColMd1 = "VideoSecond-module--app-col-md-1--bb2k5";
export var appColMd2 = "VideoSecond-module--app-col-md-2--7pdWk";
export var appColMd3 = "VideoSecond-module--app-col-md-3--R-yM4";
export var appColMd4 = "VideoSecond-module--app-col-md-4--qYLRN";
export var appColMd5 = "VideoSecond-module--app-col-md-5--bqxUs";
export var appColMd6 = "VideoSecond-module--app-col-md-6--BYkRX";
export var appColMd7 = "VideoSecond-module--app-col-md-7--fu6D0";
export var appColMd8 = "VideoSecond-module--app-col-md-8--SwATq";
export var appColMd9 = "VideoSecond-module--app-col-md-9--IGZQM";
export var appColMd10 = "VideoSecond-module--app-col-md-10--82EoI";
export var appColMd11 = "VideoSecond-module--app-col-md-11--orWy3";
export var appColMd12 = "VideoSecond-module--app-col-md-12--EcFxP";
export var appColSm1 = "VideoSecond-module--app-col-sm-1--rlysG";
export var appColSm2 = "VideoSecond-module--app-col-sm-2--Ns5kv";
export var appColSm3 = "VideoSecond-module--app-col-sm-3--lNGZb";
export var appColSm4 = "VideoSecond-module--app-col-sm-4--j2cRl";
export var appColSm5 = "VideoSecond-module--app-col-sm-5--oNZWl";
export var appColSm6 = "VideoSecond-module--app-col-sm-6--Xk7S+";
export var appColSm7 = "VideoSecond-module--app-col-sm-7--uE7vI";
export var appColSm8 = "VideoSecond-module--app-col-sm-8--XIO-v";
export var appColSm9 = "VideoSecond-module--app-col-sm-9--tGQT9";
export var appColSm10 = "VideoSecond-module--app-col-sm-10--w+MEh";
export var appColSm11 = "VideoSecond-module--app-col-sm-11--6jgOa";
export var appColSm12 = "VideoSecond-module--app-col-sm-12--qpJiQ";
export var appPseudo = "VideoSecond-module--app-pseudo--ufd3i";
export var appFormLabel = "VideoSecond-module--app-form-label--JuJCK";
export var appFormLabelText = "VideoSecond-module--app-form-label-text--dUeQM";
export var appFormInput = "VideoSecond-module--app-form-input--jSQn0";
export var appFormLabelTextFocus = "VideoSecond-module--app-form-label-text-focus--aogUz";
export var appFormLabelError = "VideoSecond-module--app-form-label-error--s-ISd";
export var appFormError = "VideoSecond-module--app-form-error--dhURP";
export var video = "VideoSecond-module--video--1Pr7e";
export var video__text = "VideoSecond-module--video__text--6lC2v";