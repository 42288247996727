import React, {useState, useEffect } from "react";
import Application from "../components/Application/Application";
import ApplicationSteps from "../components/ApplicationSteps/ApplicationSteps";
import Header from '../components/Header/Header'
import Layout from "../components/Layout/Layout";
import LookingFor from "../components/LookingFor/LookingFor";
import Offer from "../components/Offer/Offer";
import Seo from "../components/Seo/Seo";
import VerticalButton from '../components/VerticalButton/VerticalButton'
import Video from '../components/Video/Video'
import VideoSecond from '../components/VideoSecond/VideoSecond'
import CookieConsent from "../components/CookieConsentReact/CookieConsent";

export default function Home() {
  const [lookingForOffsetTop, setLookingForOffsetTop] = useState(0);
  const [stepsOffsetTop, setStepsOffsetTop] = useState(0);

  const lookingForHandler = (top) => {
    setLookingForOffsetTop(top)
  }

  const stepsHandler = (top) => {
    setStepsOffsetTop(top)
  }
  
  return (

      <Layout>
        <Seo title='Brand Master/ka'/>
        <Header />
        <LookingFor lookingForHandler={lookingForHandler}/>
        <Video />
        <Offer />
        <VideoSecond />
        <ApplicationSteps stepsHandler={stepsHandler} />
        <Application />
        <VerticalButton lookingForOffsetTop={lookingForOffsetTop} stepsOffsetTop={stepsOffsetTop}/>
          <CookieConsent />
      </Layout>

  )
}
