import React from 'react';
import Form from '../Form/Form';
import * as classes from './Application.module.scss'

export default function Application() {
    return (
        <section className={classes.application}>
            <div className={classes.application__container}>
                <div className={classes.application__wrapper}>
                    <h2 className={classes.application__title}>Aplikuj!</h2>
                    <Form />
                </div>
            </div>
        </section>
    )
}
