import React, {useState} from 'react';
import * as classes from './VideoSecond.module.scss'
import VideoModal from "../VideoModal/VideoModal";

const Video = () => {
    const [videoState, setVideoState] = useState(false)

    const openVideo = () => setVideoState(true)
    const closeVideo = () => setVideoState(false)

    return (
        <>
            {videoState ?  <VideoModal url={"https://media.brandmasterzy.pl/praca.mp4"} closeVideo={closeVideo} /> : null}
            <div className={classes.video} onClick={openVideo}>
                <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M43.0722 82.4205L43.071 83.8079L44.4584 83.8079L45.6231 83.8079L47.0072 83.8079L47.0092 82.4237C47.018 76.1511 49.2543 70.1648 53.1884 65.7709C57.1181 61.3819 62.4106 58.9458 67.8946 58.9362L69.2783 58.9338L69.2783 57.5501L69.2783 56.2493L69.2783 54.8616L67.8907 54.8631C61.2579 54.8705 54.9342 57.8178 50.2961 62.998C45.6624 68.1732 43.0787 75.1605 43.0722 82.4205Z" fill="#FFCA3B" stroke="#FFCA3B" strokeWidth="2.77225"/>
                    <path d="M43.0722 31.2682L43.071 29.8808L44.4584 29.8808L45.6231 29.8808L47.0072 29.8808L47.0092 31.265C47.018 37.5376 49.2543 43.5239 53.1884 47.9179C57.1181 52.3068 62.4106 54.7429 67.8946 54.7525L69.2783 54.7549L69.2783 56.1386L69.2783 57.4395L69.2783 58.8271L67.8907 58.8256C61.2579 58.8182 54.9342 55.8709 50.2961 50.6907C45.6624 45.5155 43.0787 38.5283 43.0722 31.2682Z" fill="#FFCA3B" stroke="#FFCA3B" strokeWidth="2.77225"/>
                    <circle cx="56.1753" cy="56.1362" r="52.831" stroke="#FFCA3B" strokeWidth="5.31156"/>
                </svg>
                <p className={classes.video__text}>Posłuchaj jak rozwijają się<br /> nasi Brand Masterzy</p>
            </div>
        </>
    )
};

export default Video;
