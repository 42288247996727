import React from 'react';
import * as classes from './Footer.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import face from '../../images/footer-img.jpg';
import contactInfo from '/static/contactInfo.pdf';
import privacyPolicy from '/static/privacyPolicyCookies.pdf';
import terms from '/static/regulamin.pdf';

export default function Footer() {
	return (
		<footer className={classes.footer}>
			<div className={classes.container}>
				<div className={classes.footer__wrapper}>
					<div className={classes.footer__firms}>
						<div className={classes.footer__firm}>
							<StaticImage className={classes.footer__firmLogo} src="../../images/logo-cursor.png"
							             placeholder="blurred" quality={95} alt="Logo CURSOR" objectFit='contain'/>
							<p className={classes.footer__firmDesc}>
								Jesteśmy liderem na rynku wsparcia sprzedaży oraz marketingu. Świadczymy swoje usługi
								dla wielu firm w&nbsp;Polsce już od 20 lat. Przy współpracy z British American Tobacco
								będziemy Cię wspierać i&nbsp;przeprowadzimy przez proces rekrutacji.
							</p>
						</div>
						<div className={classes.footer__firm}>
							<StaticImage className={classes.footer__firmLogo} src="../../images/logo-bat.png"
							             placeholder="blurred" quality={95} alt="Logo British American Tobacco"
							             objectFit='contain'/>
							<p className={classes.footer__firmDesc}>
								British American Tobacco to koncern o długich tradycjach międzynarodowych i świadomy
								pracodawca. Wie, jak ważne jest, by praca skierowana do młodych ludzi kształtujących
								swoje doświadczenie dawała nieograniczone możliwości rozwoju.
							</p>
						</div>
					</div>
					<div>
						<p className={classes.footer__copy}>©{new Date().getFullYear()} OEX Cursor</p>
						<ul className={classes.footer__docs}>
							<li className={classes.footer__doc}>
								<a href={terms} target="_blank" rel="noreferrer" className={classes.footer__link}>Regulamin</a>
							</li>
							<li className={classes.footer__doc}>
								<a href={privacyPolicy} target="_blank" rel="noreferrer"  className={classes.footer__link}>Polityka prywatności oraz plików cookies</a>
							</li>
							<li className={classes.footer__doc}>
								<button id={"cc-settings"} className={classes.footer__link}>Ustawienia cookies</button>
							</li>
							<li className={classes.footer__doc}>
								<a href={contactInfo} target="_blank" rel="noreferrer"  className={classes.footer__link}>Kontakt</a>
							</li>
						</ul>
					</div>
					<img src={face} alt="footer face" className={classes.footer__img}/>
                    <svg className={classes.footer__figure} width="300" height="301" viewBox="0 0 300 301" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M300 0.655273C134.315 0.655273 0 134.97 0 300.655H300V0.655273Z" fill="#FFCA3B"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M300 0.655273H0V300.655C0 134.97 134.315 0.655273 300 0.655273Z" fill="#162B62"/>
                    </svg>
				</div>
			</div>
		</footer>
	)
}

// <StaticImage className={classes.footer__img} src="../../images/footer-img.jpg" placeholder="blurred" quality={95} alt="footer face" />
