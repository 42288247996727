// extracted by mini-css-extract-plugin
export var appNameSection = "Video-module--app-name-section--ZqYuO";
export var appText = "Video-module--app-text--vXCUG";
export var appButton = "Video-module--app-button--rd+aP";
export var appButtonElement = "Video-module--app-button-element--P2v2U";
export var appContainer = "Video-module--app-container--41ltu";
export var container = "Video-module--container--rHy7x";
export var appRow = "Video-module--app-row--zgQCl";
export var appCol = "Video-module--app-col--0uceK";
export var appCol1 = "Video-module--app-col-1--EOj4i";
export var appCol2 = "Video-module--app-col-2--Fwr5D";
export var appCol3 = "Video-module--app-col-3--NiZPJ";
export var appCol4 = "Video-module--app-col-4--Io7VZ";
export var appCol5 = "Video-module--app-col-5--AuKO7";
export var appCol6 = "Video-module--app-col-6--f1Wmq";
export var appCol7 = "Video-module--app-col-7--Qmevu";
export var appCol8 = "Video-module--app-col-8--zT4iT";
export var appCol9 = "Video-module--app-col-9--Ci1RL";
export var appCol10 = "Video-module--app-col-10--NH82P";
export var appCol11 = "Video-module--app-col-11--h2a+V";
export var appCol12 = "Video-module--app-col-12--j2B1J";
export var appColXl1 = "Video-module--app-col-xl-1--6fOTL";
export var appColXl2 = "Video-module--app-col-xl-2--qaxgt";
export var appColXl3 = "Video-module--app-col-xl-3--DJXps";
export var appColXl4 = "Video-module--app-col-xl-4--A7Ud7";
export var appColXl5 = "Video-module--app-col-xl-5--C3c3g";
export var appColXl6 = "Video-module--app-col-xl-6--aX2Fc";
export var appColXl7 = "Video-module--app-col-xl-7--EN04N";
export var appColXl8 = "Video-module--app-col-xl-8--lIC2x";
export var appColXl9 = "Video-module--app-col-xl-9--eDO0e";
export var appColXl10 = "Video-module--app-col-xl-10--C-Qnc";
export var appColXl11 = "Video-module--app-col-xl-11--xL9Gh";
export var appColXl12 = "Video-module--app-col-xl-12--F8Hdc";
export var appColLg1 = "Video-module--app-col-lg-1--StWSo";
export var appColLg2 = "Video-module--app-col-lg-2--i9nnA";
export var appColLg3 = "Video-module--app-col-lg-3--Ii3FF";
export var appColLg4 = "Video-module--app-col-lg-4--Omhfv";
export var appColLg5 = "Video-module--app-col-lg-5--5npmq";
export var appColLg6 = "Video-module--app-col-lg-6--yb9tN";
export var appColLg7 = "Video-module--app-col-lg-7--3MXM4";
export var appColLg8 = "Video-module--app-col-lg-8--hg+5L";
export var appColLg9 = "Video-module--app-col-lg-9--sdV2W";
export var appColLg10 = "Video-module--app-col-lg-10--20BHC";
export var appColLg11 = "Video-module--app-col-lg-11--x1hlC";
export var appColLg12 = "Video-module--app-col-lg-12--Frl-n";
export var appColMd1 = "Video-module--app-col-md-1--zOH5o";
export var appColMd2 = "Video-module--app-col-md-2--oSB4M";
export var appColMd3 = "Video-module--app-col-md-3--qSE42";
export var appColMd4 = "Video-module--app-col-md-4--BN2Ee";
export var appColMd5 = "Video-module--app-col-md-5--CDprZ";
export var appColMd6 = "Video-module--app-col-md-6--CO7vM";
export var appColMd7 = "Video-module--app-col-md-7--IDdwD";
export var appColMd8 = "Video-module--app-col-md-8--LNk79";
export var appColMd9 = "Video-module--app-col-md-9--MeXSQ";
export var appColMd10 = "Video-module--app-col-md-10--W9cwG";
export var appColMd11 = "Video-module--app-col-md-11--1sRxF";
export var appColMd12 = "Video-module--app-col-md-12--otjr8";
export var appColSm1 = "Video-module--app-col-sm-1--oRUV4";
export var appColSm2 = "Video-module--app-col-sm-2--NFVl3";
export var appColSm3 = "Video-module--app-col-sm-3--cK6P6";
export var appColSm4 = "Video-module--app-col-sm-4--8C6bH";
export var appColSm5 = "Video-module--app-col-sm-5--TDKgA";
export var appColSm6 = "Video-module--app-col-sm-6--VTFP3";
export var appColSm7 = "Video-module--app-col-sm-7--1BLTL";
export var appColSm8 = "Video-module--app-col-sm-8--HIEJ2";
export var appColSm9 = "Video-module--app-col-sm-9--gEpfc";
export var appColSm10 = "Video-module--app-col-sm-10--4gcjI";
export var appColSm11 = "Video-module--app-col-sm-11--qctlV";
export var appColSm12 = "Video-module--app-col-sm-12--i23gZ";
export var appPseudo = "Video-module--app-pseudo--KyaiU";
export var appFormLabel = "Video-module--app-form-label--GcwXx";
export var appFormLabelText = "Video-module--app-form-label-text--wgUun";
export var appFormInput = "Video-module--app-form-input--BZCB2";
export var appFormLabelTextFocus = "Video-module--app-form-label-text-focus--LgqSn";
export var appFormLabelError = "Video-module--app-form-label-error--OSHct";
export var appFormError = "Video-module--app-form-error--AbWkg";
export var video = "Video-module--video--LuUb-";
export var video__text = "Video-module--video__text--TCqT3";